<!-- 首页右边框-主内容 -->
<template>
	<div>
		<el-row :gutter="20">
			<el-col :span="12">
				<el-card class="box-card" style="height: 350px;" shadow="never">
					<div slot="header" class="clearfix">
						<span>访问流量</span>
						<span style="color:#ff6e70;font-size: 12px;">（30天）</span>
					</div>
					<!-- 统计图容器 -->
					<div ref="myCharta" style="width: 100%; height: 250px;"></div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="height: 350px;" shadow="never">
					<div slot="header" class="clearfix">
						<span>消费统计</span>
						<span style="color:#ff6e70;font-size: 12px;">（30天）</span>
					</div>
					<!-- 统计图容器 -->
					<div ref="myChartb" style="width: 100%; height: 250px;"></div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="my-3">
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="godo('/shop/goods/list')">
						<i class="el-icon-user-solid bg-primary h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">产品中心</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="godo('/web')">
						<i class="el-icon-s-finance bg-success h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">优惠券</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="godo('/set/notice')">
						<i
							class="el-icon-s-order h4 mb-0 text-white text-center mr-3"
							style="width: 40px; height: 40px; text-align: center;line-height: 40px;background-color: #ff54ad;"
						></i>
						<div><small class="text-muted">活动公告</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="gobaidu">
						<i class="el-icon-s-data bg-warning h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">订单查询</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="daima">
						<i
							class="el-icon-s-order h4 mb-0 text-white text-center mr-3"
							style="width: 40px; height: 40px; text-align: center;line-height: 40px;background-color: #5591ff;"
						></i>
						<div><small class="text-muted">站点源码</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="daimas">
						<i class="el-icon-s-finance bg-danger h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">站点维护</small></div>
					</div>
				</el-card>
			</el-col>
		</el-row>

		<el-row :gutter="20" class="my-3">
			<el-col :span="12" class="d-flex flex-column" style="height: 400px; justify-content: space-between;">
				<el-card class="box-card" shadow="never">
					<div slot="header" class="clearfix">
						<span>TOP产品</span>
						<span style="color:#ff6e70;font-size: 12px;">（30天）</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="godo('/shop/goods/list')">查看产品</el-button>
					</div>
					<div ref="myChart" style="width: 100%; height: 300px;"></div>
				</el-card>
			</el-col>

			<el-col :span="12">
				<el-card class="box-card" style="height: 400px;" shadow="never">
					<div slot="header" class="clearfix">
						<span>仓库物料</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="godo('/cklb/list/list')">查看库存</el-button>
					</div>
					<div class="d-flex">
						<el-table :data="tableData" style="width: 49%;margin-right: 1%;" height="300px" border size="mini">
							<el-table-column prop="id" label="编号" width="50" align="center"></el-table-column>
							<el-table-column prop="mc" label="物料名称"></el-table-column>
							<el-table-column prop="num" label="数量" width="80" align="center"></el-table-column>
						</el-table>
						<el-table :data="tableDatb" style="width: 49%;margin-left: 1%;" height="300px" border size="mini">
							<el-table-column prop="id" label="编号" width="50" align="center"></el-table-column>
							<el-table-column prop="mc" label="物料名称"></el-table-column>
							<el-table-column prop="num" label="数量" width="80" align="center"></el-table-column>
						</el-table>
					</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { mapState } from 'vuex'; // 引入 全局 公共数据
import 'echarts/map/js/china.js';
import * as vueEcharts from 'echarts';
import moment from 'moment';
let echarts = require('echarts');
export default {
	data() {
		return {
			fanwen: [], //访问数据
			fday: [], //时间
			fwl: [], //访问量
			zixun: [], //咨询量
			day: '',
			tableData: [],
			tableDatb: [],
			myChart: null,
			moment,
			days: [],
			cheshia: [],
			cheshib: [],
			cheshic: []
		};
	},
	// 动态计算过滤器，计算col-3百分比分配页面
	filters: {
		getCol(total) {
			return `col-${12 / total}`;
		}
	},
	computed: {
		// 引入store-modules的user模块里面的user(存储用户相关的数据)
		...mapState({
			user: state => state.user.user,
			navBar: state => state.menu.navBar
		})
	},
	// 生命周期
	created() {
		this.axios
			.get('/admin/cklb/1?limit=9999', {
				//商品列表
				token: true
			})
			.then(res => {
				let data = res.data.data.list;
				for(let i in data){
					if(i < 10){
						this.tableData.push(data[i])
					}else if(i<20){
						this.tableDatb.push(data[i])
					}
				}
				// this.tableData = data;
				console.log(this.tableData)
			});
	},
	// dom渲染完毕 mounted生命周期
	mounted() {
		// 画统计图
		this.drawLine();
		this.changeRecent();
	},
	// 画统计图方法
	methods: {
		changeRecent() {
			for (let i = 0; i < 30; i++) {
				this.days.push(moment(new Date().getTime() - i * 1000 * 24 * 60 * 60).format('MM-DD'));
				this.cheshia.push(Math.floor(Math.random() * (10000 - 500)) + 500);
				this.cheshib.push(Math.floor(Math.random() * (10000 - 500)) + 500);
				this.cheshic.push(Math.floor(Math.random() * (10000 - 500)) + 500);
			}
		},
		godo(e) {
			this.$router.push({ path: e });
		},
		gobaidu() {
			window.open('https://index.baidu.com/v2/index.html#/', '_blank');
		},
		// 删除单个规格卡片
		daima() {
			this.$confirm('如需获得站点源码，请联系：17328292827', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			});
		},
		daimas() {
			this.$confirm('24小时站点维护，请联系：17328292827', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			});
		},
		drawLine() {
			var now = moment().format('YYYY-MM-DD');
			const guangxiJson = require('echarts/map/js/china.js');
			// 初始化echarts实例
			let myChart = echarts.init(this.$refs.myChart);
			let myCharta = echarts.init(this.$refs.myCharta);
			let myChartb = echarts.init(this.$refs.myChartb);
			var that = this;
			// 数据过滤
			// 访问量

			var that = this;
			setTimeout(function() {
				// 配置参数
				myChart.setOption({
					title: {
					    text: now
					  },
					legend: {},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						boundaryGap: [0, 0.01]
					},
					yAxis: {
						type: 'category',
						data: ['产品1', '产品2', '产品3', '产品4', '产品5', '产品6', '产品7', '产品8', '产品9', '产品10']
					},
					series: [
						{
							type: 'bar',
							name: '下单次数',
							data: [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100]
						}
					]
				}),
					myCharta.setOption({
						//提示
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},
						legend: {},
						grid: {
							left: '3%',
							right: '5%',
							bottom: '3%',
							containLabel: true
						},
						//x轴
						xAxis: [
							{
								type: 'category',
								boundaryGap: false,
								data: that.days
							}
						],
						//y轴
						yAxis: [
							{
								type: 'value'
							}
						],
						//内容
						series: [
							{
								name: '日访问流量',
								type: 'line',
								areaStyle: {},
								emphasis: {
									focus: 'series'
								},
								data: [1231, 2, 3, 45, 546, 6, 214, 1231, 2, 3, 45, 546, 6, 2141, 231, 2, 3, 45, 546, 6, 214, 1231, 2, 3, 45, 546, 6, 214, 45, 546]
							}
						]
					}),
					myChartb.setOption({
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},
						legend: {},
						grid: {
							left: '3%',
							right: '5%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							data: that.days
						},
						yAxis: {
							type: 'value'
						},
						series: [
							{
								type: 'bar',
								name: '营收流水',
								emphasis: {
									focus: 'series'
								},
								data: [
									1231,
									232,
									233,
									245,
									546,
									226,
									214,
									1231,
									122,
									123,
									145,
									546,
									126,
									2141,
									231,
									232,
									213,
									45,
									546,
									316,
									214,
									1231,
									122,
									323,
									145,
									546,
									326,
									214,
									145,
									546
								]
							},
							{
								type: 'bar',
								name: '支出流水',
								emphasis: {
									focus: 'series'
								},
								data: [
									1231,
									232,
									233,
									245,
									546,
									226,
									214,
									1231,
									122,
									123,
									145,
									546,
									126,
									2141,
									231,
									232,
									213,
									45,
									546,
									316,
									214,
									1231,
									122,
									323,
									145,
									546,
									326,
									214,
									145,
									546
								]
							}
						]
					});
			}, 1000);
		}
	},
	beforeUnmount() {
		console.log('beforeUnmount');
		if (!myChart) {
			return;
		}
		// window.removeEventListener('resize', this.__resizeHandler)
		myChart.dispose();
		let myChart = null;
	}
};
</script>

<style></style>
